$(function()
{
    // Models
    var cart     = new Cart();

    var $missing_components_modal = $('#missing-components-modal');
    var $new_search_modal = $('#new-search-confirmation-modal');
    var $vin_decoding_modal = $('#vin-decoding-modal');
    var $search = $('#search');
    var $cart = $('#cart-container');

    var reload_carts = function(process) {
        return {
            success: function()
            {
                $('#carts-wrapper').load(location.href+' #carts-list');

                if(process)
                {
                    process();
                }
            }
        }
    }

    $(document).delegate('.delete-cart','click', function(e){
        e.preventDefault();

        var items_count = $(this).closest('tr').find('.amount').html();
        var cart_id = $(this).closest('tr').data('cart_id');

        if(items_count === 0)
        {
            cart.delete_cart(cart_id,reload_carts());
        }
        else
        {
            if(confirm('Voulez-vous réellement supprimer le panier d\'achat ?'))
            {
                cart.delete_cart(cart_id,reload_carts());
            }
        }
    });

    $(document).delegate('#carts_search','keyup', function()
    {
        var search = $(this).val().toUpperCase();
        var to_search_string = new RegExp(search+".*");

        $(".to_search").each(function(){
            to_search_string.test($(this).data("to_search")) ? $(this).show() :  $(this).hide();
        });
    });

    $(document).on('click', '#cart-toggle', function(e) {
        e.preventDefault();
        $('#cart-compact-view').slideToggle("fast");
        e.stopPropagation();
    });

    $(document).on('click', 'body', function(e) {
        if ($(e.target).closest("#cart-compact-view").length === 0) {
            $("#cart-compact-view:visible").stop(true, true).slideUp("fast");
        }
    });

    $(document).on('click', '.cart-remove-item', function(e) {
        e.preventDefault();
        var row = $(this).closest('tr');
        var item_id = row.data('item_id');

        if (!item_id) {
            return;
        }

        cart.delete_item(item_id, {
            success: function() {
                row.fadeOut('fast', function() {
                    $(this).remove();
                    $(document).trigger('cart-update');
                });
            }
        });
    });

    $(document).on('cart-update', function() {
        $('#cart-reload').load(location.href + ' #cart-reload');

        cart.get_prices({
            success: function(prices) {
                $('.cart-items-count').html(Object.keys(prices.items).length);
                $('.transport-fees-amount').html(prices.transport_fees);
                $('.transport-fees-total').html(prices.transport_fees_total);
            }
        });
    });

    $(document).on('click', '.missing-components', function(e) {
        e.preventDefault();

        var stock_id = $(this).data('stock_id');

        cart.get_missing_composites(stock_id, {
            success: function(data) {
                $missing_components_modal.find(".modal-body").html(data);
            }
        });
    });

    $(document).on('click', '.new-search', function(e) {
        e.preventDefault();
        var pending_parts = $search.find('.part').length;
        var cart_items = $cart.find('.compact-stock-row').length;

        if (pending_parts > 0 || cart_items > 0) {
            $new_search_modal.modal("show");
        } else {
            create_cart();
        }
    });

    $new_search_modal.on('click', '#new-search-confirmation', function() {
        create_cart();
    });

    $vin_decoding_modal.on('click', '#decode-vin', function() {
        var vin = $('#vin-input-modal').val();

        if (!vin) {
            return false;
        }

        cart.decode_vin(vin, {
            success: function(data) {
                $vin_decoding_modal.find('#vin-decoding-details').html(data);
            }
        });
    });

    $vin_decoding_modal.on('shown.bs.modal', function() {
        $('#vin-input-modal').focus();
    });

    $vin_decoding_modal.on('keypress', '#vin-input-modal', function(e) {
        if (e.keyCode == 13) {
            $('#decode-vin').trigger('click');
        }
    });

    function create_cart()
    {
        window.location.href = document.base_url +'cart/create';
    }
});